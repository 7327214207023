// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-template-index-jsx": () => import("./../src/templates/ProjectTemplate/index.jsx" /* webpackChunkName: "component---src-templates-project-template-index-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-cost-optimization-of-fire-protection-js": () => import("./../src/pages/services/cost-optimization-of-fire-protection.js" /* webpackChunkName: "component---src-pages-services-cost-optimization-of-fire-protection-js" */),
  "component---src-pages-services-education-js": () => import("./../src/pages/services/education.js" /* webpackChunkName: "component---src-pages-services-education-js" */),
  "component---src-pages-services-execution-of-fire-protection-works-js": () => import("./../src/pages/services/execution-of-fire-protection-works.js" /* webpackChunkName: "component---src-pages-services-execution-of-fire-protection-works-js" */),
  "component---src-pages-services-laboratory-tests-js": () => import("./../src/pages/services/laboratory-tests.js" /* webpackChunkName: "component---src-pages-services-laboratory-tests-js" */)
}

